<script lang="ts">
export default {
  mounted: (el: any) => {
    console.log(el);

    let didScroll: boolean = false;
    let scrollTop: number = 0;
    let lastScrollTop: number = 0;

    // on scroll, let the interval function know the user has scrolled
    document.addEventListener("scroll", (event) => {
      didScroll = true;
      scrollTop = window.scrollY;
    });

    // run hasScrolled() and reset didScroll status
    setInterval(function () {
      if (didScroll) {
        if (lastScrollTop < scrollTop) {
          if (scrollTop > 64) {
            hasScrolledDown();
          }
        } else if (lastScrollTop > scrollTop) {
          hasScrolledUp();
        }

        didScroll = false;
        lastScrollTop = scrollTop;
      }
    }, 250);

    function hasScrolledUp() {
      el.classList.remove("opacity-0");
    }

    function hasScrolledDown() {
      el.classList.add("opacity-0");
    }
  },
};
</script>
