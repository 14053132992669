<template>
  <n-config-provider
    :theme-overrides="themeOverrides"
    :locale="deDE"
    :date-locale="dateDeDE"
  >
    <slot></slot>
  </n-config-provider>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  NConfigProvider,
  GlobalThemeOverrides,
  deDE,
  dateDeDE,
} from "naive-ui";

const themeOverrides: GlobalThemeOverrides = {
  common: {
    primaryColor: "#c63c4e",
    primaryColorHover: "#c63c4e",
    textColorBase: "#c63c4e",
  },
  Button: {
    textColor: "#c63c4e",
  },
  InternalSelection: {
    textColor: "#c63c4e",
    placeholderColor: "#c63c4e",
    heightMedium: "43px",
    border: "1px solid #c63c4e",
    borderFocus: "2px solid #c63c4e",
    borderHover: "2px solid #c63c4e",
    borderActive: "2px solid #c63c4e",
    paddingMultiple: "0 1rem 0 1rem",
    caretColor: "#c63c4e",
    boxShadowActive: "",
    boxShadowFocus: "",
    boxShadowHover: "",
    clearColor: "#c63c4e",
    clearSize: "22px",
    arrowColor: "#c63c4e",
  },
  Input: {
    textColor: "#c63c4e",
    placeholderColor: "#c63c4e",
    heightMedium: "43px",
    border: "1px solid #c63c4e",
    borderFocus: "2px solid #c63c4e",
    borderHover: "2px solid #c63c4e",
    caretColor: "#c63c4e",
    boxShadowFocus: "",
    clearColor: "#c63c4e",
    clearSize: "22px",
  },
  Select: {
    peers: {
      InternalSelectMenu: {},
    },
  },
  Tag: {
    border: "0px",
    color: "rgba(0,0,0,1)",
  },
};

export default defineComponent({
  setup() {
    return {
      deDE,
      dateDeDE,
      themeOverrides,
    };
  },
  components: {
    NConfigProvider,
  },
});
</script>
