<template>
  <div>
    <div
      v-if="showFilterOverlay"
      @click.stop="applyFilters"
      class="fixed bottom-0 left-0 right-0 top-0 z-40 block bg-gray-700 opacity-90"
    ></div>

    <!-- Start Filter Overlay -->
    <Transition name="slide-fade">
      <div
        class="fixed bottom-0 left-0 top-0 z-50 h-auto min-w-96 max-w-96 bg-white p-6 pr-1 pt-20 xs:w-full xs:max-w-none"
        v-if="showFilterOverlay"
      >
        <div class="absolute right-4 top-4 text-gray-500">
          <i
            @click="applyFilters"
            class="fa-regular fa-xmark cursor-pointer p-2"
          ></i>
        </div>
        <div class="grid h-full grid-rows-[1fr_max-content]">
          <NScrollbar class="h-full pb-8">
            <!-- Start Facets -->
            <NCollapse
              arrow-placement="right"
              :accordion="true"
              class="block w-full pr-6 text-sm font-medium text-gray-900"
            >
              <NCollapseItem
                v-for="(filter, key) in facets"
                :title="String(key)"
                :name="filter[0].fieldHandle"
              >
                <NScrollbar class="max-h-60">
                  <NTree
                    block-line
                    cascade
                    checkable
                    :data="filter"
                    @update:checked-keys="updateCheckedKeys"
                    :checked-keys="
                      activeFacets[filter[0].fieldHandle]?.values ?? []
                    "
                    label-field="title"
                    key-field="id"
                    :check-on-click="true"
                    class="pr-6"
                  ></NTree>
                </NScrollbar>
              </NCollapseItem>
            </NCollapse>
            <!-- End Facets -->

            <!-- Start applied filter tags -->

            <AppliedFilters
              :search="search"
              :active-facets="activeFacets"
              :flat-facets="flatFacets"
              :remove-active-facet="removeActiveFacet"
              :remove-search="removeSearch"
              :apply-items="false"
            ></AppliedFilters>

            <!-- End Applied filter tags -->
          </NScrollbar>
          <div class="mr-6 border-t-2 pt-4">
            <div class="pb-4">
              <i
                v-if="isLoadingFilters"
                class="fa-regular fa-spinner animate-spin text-black"
              ></i>
              <span v-else class="text-sm font-bold italic">{{
                pagination.total
              }}</span>
              <span class="pl-1 text-sm italic">Ergebnisse gefunden</span>
            </div>
            <div class="flex content-stretch gap-4">
              <div class="btn flex-1 text-sm" @click="applyFilters">
                Filter anwenden
              </div>
              <div
                class="btn flex-1 bg-kmsuRed text-sm"
                @click="deleteAppliedFilters(false)"
              >
                Filter löschen
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <!-- End Filter Overlay -->

    <!-- Start Search and Filter button -->
    <div class="mb-16 s:mb-12">
      <div class="mx-auto max-w-xl">
        <div class="flex gap-2">
          <div @click="toggleFilterOverlay" class="btn__square">
            <i v-if="!showFilterOverlay" class="fa-regular fa-filter-list"></i>
            <i v-else class="fa-regular fa-xmark cursor-pointer"></i>
          </div>
          <div class="relative w-full">
            <input
              v-model="search"
              type="search"
              id="search-dropdown"
              class="z-20 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500"
              placeholder="Suchen"
              required
              @keypress="handleEnter"
            />
            <button
              @click="filterAndRemoveNonAvailableFacets(true)"
              class="absolute end-0 top-0 h-full rounded-e-lg border border-lightBlue bg-lightBlue p-2.5 text-sm font-medium text-white focus:outline-none focus:ring-4 focus:ring-blue-300"
            >
              <svg
                class="h-4 w-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <span class="sr-only">Suchen</span>
            </button>
          </div>
        </div>
        <AppliedFilters
          :search="search"
          :active-facets="activeFacets"
          :flat-facets="flatFacets"
          :remove-active-facet="removeActiveFacet"
          :remove-search="removeSearch"
          :apply-items="true"
        ></AppliedFilters>

        <div
          v-if="Object.keys(activeFacets).length || search"
          class="mt-6 cursor-pointer text-sm text-kmsuRed"
          @click="deleteAppliedFilters(true)"
        >
          Alle Filter zurücksetzen <i class="fa-regular fa-xmark"></i>
        </div>
      </div>
    </div>
    <!-- End Search and Filter button -->

    <!-- Start Results -->
    <div class="mb-8 text-xl s:mb-6">
      {{ pagination.total }} Ergebnisse gefunden
    </div>
    <div class="exponat-list relative">
      <div
        class="absolute bottom-0 left-0 right-0 top-0 rounded-sm bg-gray-500 opacity-75"
        v-show="isLoading"
      >
        <div
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
        >
          <i class="fa-regular fa-spinner animate-spin text-6xl text-white"></i>
        </div>
      </div>
      <a class="exponat-list-item" :href="item.link" v-for="item in items">
        <img
          class="aspect-video rounded-md"
          :src="item.image || 'assets/placeholder.webp'"
        />
        <div class="flex h-full flex-col content-stretch px-2">
          <div class="mt-4 text-kmsuRed">{{ item.title }}</div>
          <div class="h-full text-sm text-gray-500">{{ item.subTitle }}</div>

          <i class="block pt-6 text-xs text-gray-400">{{
            item.preservation
          }}</i>
        </div>
      </a>
    </div>
    <!-- End Results -->

    <!-- Start Pagination -->
    <div class="mx-auto mt-12 w-max xs:mt-8">
      <NPagination
        class="hidden w-max minS:flex"
        v-model:page="page"
        v-model:page-size="pageSize"
        :page-count="Number(pagination.totalPages)"
        show-size-picker
        :page-sizes="[8, 16, 32, 64]"
        :on-update-page="handlePageUpdate"
        :on-update-page-size="updatePageSize"
      />

      <div class="hidden w-max s:block">
        <n-pagination
          v-model:page="page"
          :page-count="Number(pagination.totalPages)"
          simple
          :on-update-page="handlePageUpdate"
        />
      </div>
    </div>
    <!-- End Pagination -->
  </div>
</template>

<script lang="ts" setup>
import axios from "axios";
import { ref, watch } from "vue";
import {
  NTree,
  TreeOption,
  NCollapse,
  NCollapseItem,
  NPagination,
  NScrollbar,
} from "naive-ui";
import AppliedFilters from "./AppliedFilters.vue";

const items = ref<any[]>([]);
const preloadedResults = ref<any[]>([]);
const search = ref<string>("");
const facets = ref<any[]>([]);
const isLoading = ref(false);
const isLoadingFilters = ref(false);
const showFilterOverlay = ref(false);
const filterPromise = ref<Promise<any>>();

const page = ref(1);
const pageSize = ref(8);

const activeFacets = ref(
  {} as Record<string, { values: string[]; fieldName: string; title: string }>,
);
const pagination = ref({} as Record<string, string | number>);
const flatFacets = ref([] as any[]);
filter(true);

async function filter(applyItems = false) {
  const filterReq = axios.post("/actions/kmsu/filter", {
    filters: activeFacets.value,
    search: search.value,
    pagination: {
      page: page.value,
      limit: pageSize.value,
    },
    filterCategories: ["klasse", "ordnung", "kategorie", "stamm"],
  });

  filterPromise.value = filterReq;

  const response = await filterReq;
  if (applyItems) {
    items.value = response.data.items;
  }

  preloadedResults.value = response.data.items;
  facets.value = response.data.facets;
  pagination.value = response.data.pagination;
  flatFacets.value = response.data.flatFacets;

  // reset page if the current page is higher than the total pages
  if (
    page.value > Number(pagination.value.totalPages) &&
    pagination.value.total !== 0
  ) {
    page.value = 1;
    filterAndRemoveNonAvailableFacets();
  }
}

async function filterAndRemoveNonAvailableFacets(applyItems = false) {
  isLoadingFilters.value = true;
  if (applyItems) {
    isLoading.value = true;
  }

  await filter(applyItems);

  removeNonAvailableActiveFacets();
  isLoadingFilters.value = false;

  if (applyItems) {
    isLoading.value = false;
  }

  await filter(applyItems);
}

function updatePageSize(size: number) {
  pageSize.value = size;
  page.value = 1;

  filterAndRemoveNonAvailableFacets(true);
}

function updateCheckedKeys(
  keys: Array<string | number>,
  option: Array<TreeOption | null>,
  meta: {
    node: TreeOption | null;
    action: "check" | "uncheck";
  },
) {
  const fieldHandle = meta.node?.fieldHandle as string;
  const fieldTitle = fieldHandle.charAt(0).toUpperCase() + fieldHandle.slice(1);

  activeFacets.value[fieldHandle] = {
    values: keys as string[],
    fieldName: fieldHandle,
    title: fieldTitle,
  };

  filterAndRemoveNonAvailableFacets();
}

function removeNonAvailableActiveFacets() {
  for (const key in activeFacets.value) {
    const activeFacet = activeFacets.value[key];
    const availableFacets = flatFacets.value.filter(
      (facet) => facet.fieldHandle === activeFacet.fieldName,
    );
    const activeFacetValues = activeFacet.values;

    const newActiveFacetValues = activeFacetValues.filter((value) => {
      return availableFacets.find((facet) => facet.id === value);
    });

    activeFacets.value[key].values = newActiveFacetValues;
  }

  // delete active facets without values
  for (const key in activeFacets.value) {
    if (!activeFacets.value[key].values.length) {
      delete activeFacets.value[key];
    }
  }
}

function toggleFilterOverlay() {
  showFilterOverlay.value = !showFilterOverlay.value;

  if (showFilterOverlay.value) {
    document.documentElement.style.overflowY = "hidden";
  } else {
    document.documentElement.style.overflowY = "auto";
  }
}

async function applyFilters() {
  isLoading.value = true;
  toggleFilterOverlay();

  // wait for a possible pending filter request
  await filterPromise.value;

  // fake loading time to prevent confusion
  setTimeout(() => {
    items.value = preloadedResults.value;
    isLoading.value = false;
  }, 250);
}

function deleteAppliedFilters(applyItems = false) {
  activeFacets.value = {};
  search.value = "";
  filterAndRemoveNonAvailableFacets(applyItems);
}

function handlePageUpdate() {
  filterAndRemoveNonAvailableFacets(true);
}

function handleEnter(event: KeyboardEvent) {
  if (event.key === "Enter") {
    filterAndRemoveNonAvailableFacets(true);
  }
}

function removeActiveFacet(key: string, value: string, applyItems = false) {
  activeFacets.value[key].values = activeFacets.value[key].values.filter(
    (val) => val !== value,
  );

  filterAndRemoveNonAvailableFacets(applyItems);
}

function removeSearch(applyItems = false) {
  search.value = "";
  filterAndRemoveNonAvailableFacets(applyItems);
}
</script>

<style lang="scss">
.animate-spin {
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.n-collapse-item-arrow {
  @apply ml-auto #{!important};
}

.n-collapse-item__header-main {
  @apply italic;
}

.n-tree-node-switcher--hide {
  @apply w-0 #{!important};
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from {
  transform: translateX(-100%);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
</style>
