import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const animations = {
  //default value is 1 -> 1 * animationDurationMultiplier
  animationDurationMultiplier: 1,

  //0% -> top -> 100% -> bottom
  scrollerPosition: "85%",
  scrollerPositionMobile: "95%",

  //positive value -> fade in from bottom | negative value -> fade in from top
  yStartpoint: 120,
  yEndpoint: 0,

  //positive value -> fade in from right | negative value -> fade in from left
  xStartpoint: 0,
  xEndpoint: 0,

  // play none none reverse -> repeat animation | play none none none -> play animation once
  toggleActions: "play none none reverse",

  //Modules to not animate => Array with classnames  ex. ['webkit-slideshow-container', 'webkit-grid-container']
  modulesToNotAnimate: [""],

  //For Debugging
  activateMarkers: false,
};

let scrollerPosition = animations.scrollerPosition;

if (window.innerWidth <= 480) {
  scrollerPosition = animations.scrollerPositionMobile;
} else {
  scrollerPosition = animations.scrollerPosition;
}

function reloaded() {
  ScrollTrigger.refresh(true);
}

addEventListener("load", reloaded);

addEventListener("DOMContentLoaded", () => {
  const modularContent = gsap.utils.toArray(".fade-in-animation");

  modularContent.forEach((value: any, index: number) => {
    const classList = animations.modulesToNotAnimate as Array<string>;

    if (classList.some((className) => value.classList.contains(className))) {
      return;
    } else {
      const anim = gsap.fromTo(
        value,
        {
          autoAlpha: 0,
          y: animations.yStartpoint,
          x: animations.xStartpoint,
        },
        {
          autoAlpha: 1,
          y: animations.yEndpoint,
          x: animations.xEndpoint,
          duration: 1 * animations.animationDurationMultiplier,
          clearProps: "all",
        },
      );

      ScrollTrigger.create({
        trigger: value,
        start: `top ${scrollerPosition}`,
        animation: anim,
        markers: animations.activateMarkers,
        toggleActions: animations.toggleActions,
      });
    }
  });
});
