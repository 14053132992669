/* eslint-disable @typescript-eslint/ban-types */
/**
 *	===============================================================================================
 *		 ____ _____ _   _          _______     __                 _____           _     _    _
 *		|  _ \_   _| \ | |   /\   |  __ \ \   / /                / ____|         | |   | |  | |
 *		| |_) || | |  \| |  /  \  | |__) \ \_/ /__  _ __   ___  | |  __ _ __ ___ | |__ | |__| |
 *		|  _ < | | | . ` | / /\ \ |  _  / \   / _ \| '_ \ / _ \ | | |_ | '_ ` _ \| '_ \|  __  |
 *		| |_) || |_| |\  |/ ____ \| | \ \  | | (_) | | | |  __/ | |__| | | | | | | |_) | |  | |
 *		|____/_____|_| \_/_/    \_\_|  \_\ |_|\___/|_| |_|\___|  \_____|_| |_| |_|_.__/|_|  |_|
 *
 *	===============================================================================================
 * @author    BINARY one GmbH
 * @license Unlicensed
 */
import "flowbite";
import "/src/scss/index.scss";
import { BASEURL, SITEURL } from "@libTs/cms.globals";

import { createApp } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import naive from "naive-ui";
import "./animations";

// Component imports
import FilterOverview from "./components/FilterOverview.vue";
import NaiveUiConfig from "./components/NaiveUiConfig.vue";

// Directive imports
import VFocus from "./directives/v-focus.vue";
import VHeader from "./directives/v-header.vue";

// Service imports
import { OverlaysService } from "./services/overlays.service";
import { CookieService } from "./services/cookie.service";

gsap.registerPlugin(ScrollToPlugin);

// -----------------------------------------------------------
// DEBUG output
const ISDEBUG = import.meta.env.DEV;

if (ISDEBUG) {
  console.group("Environment loaded");
  console.log(ISDEBUG);
  console.log(
    " -----------------------------------\n",
    ` BASEURL:\t\t${BASEURL}\n`,
    ` SITEURL:\t\t${SITEURL}\n`,
    "-----------------------------------",
  );
  console.groupEnd();
}

const app = createApp({
  data() {
    return {
      overlays: new OverlaysService(),
      cookie: new CookieService(),
    };
  },
  provide() {
    return {
      overlays: this.overlays,
      cookie: this.cookie,
    };
  },
  components: {
    FilterOverview,
    NaiveUiConfig,
  },
  directives: {
    Focus: VFocus,
    Header: VHeader,
  },
});
// 'axios': AJAX library
app.use(VueAxios, axios);
app.use(naive);
app.mount("#app");
